@font-face {
	font-family: 'dropways';
	src:  url('../fonts/dropways.eot?jm47o8');
	src:  url('../fonts/dropways.eot?jm47o8#iefix') format('embedded-opentype'),
		  url('../fonts/dropways.ttf?jm47o8') format('truetype'),
		  url('../fonts/dropways.woff?jm47o8') format('woff'),
		  url('../fonts/dropways.svg?jm47o8#dropways') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.dw {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'dropways' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dw-analytics1:before {
	content: "\ee39";
}
.dw-analytics-11:before {
	content: "\ee3a";
}
.dw-analytics-21:before {
	content: "\ee3b";
}
.dw-analytics-3:before {
	content: "\ee3c";
}
.dw-analytics-4:before {
	content: "\ee3d";
}
.dw-analytics-5:before {
	content: "\ee3e";
}
.dw-analytics-6:before {
	content: "\ee3f";
}
.dw-analytics-7:before {
	content: "\ee40";
}
.dw-analytics-8:before {
	content: "\ee41";
}
.dw-analytics-9:before {
	content: "\ee42";
}
.dw-analytics-10:before {
	content: "\ee43";
}
.dw-analytics-111:before {
	content: "\ee44";
}
.dw-analytics-12:before {
	content: "\ee45";
}
.dw-analytics-13:before {
	content: "\ee46";
}
.dw-analytics-14:before {
	content: "\ee47";
}
.dw-analytics-15:before {
	content: "\ee48";
}
.dw-analytics-16:before {
	content: "\ee49";
}
.dw-analytics-17:before {
	content: "\ee4a";
}
.dw-analytics-18:before {
	content: "\ee4b";
}
.dw-analytics-19:before {
	content: "\ee4c";
}
.dw-analytics-20:before {
	content: "\ee4d";
}
.dw-analytics-211:before {
	content: "\ee4e";
}
.dw-analytics-22:before {
	content: "\ee4f";
}
.dw-logout1:before {
	content: "\ee50";
}
.dw-name:before {
	content: "\ee51";
}
.dw-logout-1:before {
	content: "\ee52";
}
.dw-user3:before {
	content: "\ee53";
}
.dw-enter:before {
	content: "\ee54";
}
.dw-user-13:before {
	content: "\ee55";
}
.dw-unlock1:before {
	content: "\ee56";
}
.dw-logout-2:before {
	content: "\ee57";
}
.dw-password:before {
	content: "\ee58";
}
.dw-lock:before {
	content: "\ee59";
}
.dw-id-card2:before {
	content: "\ee5a";
}
.dw-enter-1:before {
	content: "\ee5b";
}
.dw-keyhole:before {
	content: "\ee5c";
}
.dw-user-2:before {
	content: "\ee5d";
}
.dw-browser2:before {
	content: "\ee5e";
}
.dw-key3:before {
	content: "\ee5f";
}
.dw-login:before {
	content: "\ee60";
}
.dw-door:before {
	content: "\ee61";
}
.dw-user-3:before {
	content: "\ee62";
}
.dw-keyhole-1:before {
	content: "\ee63";
}
.dw-alarm-clock:before {
	content: "\e900";
}
.dw-antenna:before {
	content: "\e901";
}
.dw-apartment:before {
	content: "\e902";
}
.dw-shopping-bag:before {
	content: "\e903";
}
.dw-shopping-basket:before {
	content: "\e904";
}
.dw-shopping-basket-1:before {
	content: "\e905";
}
.dw-battery:before {
	content: "\e906";
}
.dw-battery-1:before {
	content: "\e907";
}
.dw-bell:before {
	content: "\e908";
}
.dw-binocular:before {
	content: "\e909";
}
.dw-sailboat:before {
	content: "\e90a";
}
.dw-book:before {
	content: "\e90b";
}
.dw-bookmark:before {
	content: "\e90c";
}
.dw-briefcase:before {
	content: "\e90d";
}
.dw-brightness:before {
	content: "\e90e";
}
.dw-browser:before {
	content: "\e90f";
}
.dw-paint-brush:before {
	content: "\e910";
}
.dw-building:before {
	content: "\e911";
}
.dw-idea:before {
	content: "\e912";
}
.dw-school-bus:before {
	content: "\e913";
}
.dw-birthday-cake:before {
	content: "\e914";
}
.dw-birthday-cake-1:before {
	content: "\e915";
}
.dw-calculator:before {
	content: "\e916";
}
.dw-calendar:before {
	content: "\e917";
}
.dw-calendar-1:before {
	content: "\e918";
}
.dw-calendar-2:before {
	content: "\e919";
}
.dw-shopping-cart:before {
	content: "\e91a";
}
.dw-money:before {
	content: "\e91b";
}
.dw-money-1:before {
	content: "\e91c";
}
.dw-money-2:before {
	content: "\e91d";
}
.dw-cctv:before {
	content: "\e91e";
}
.dw-certificate:before {
	content: "\e91f";
}
.dw-certificate-1:before {
	content: "\e920";
}
.dw-chair:before {
	content: "\e921";
}
.dw-chat:before {
	content: "\e922";
}
.dw-chat-1:before {
	content: "\e923";
}
.dw-chef:before {
	content: "\e924";
}
.dw-cursor:before {
	content: "\e925";
}
.dw-wall-clock:before {
	content: "\e926";
}
.dw-coding:before {
	content: "\e927";
}
.dw-coffee:before {
	content: "\e928";
}
.dw-coffee-1:before {
	content: "\e929";
}
.dw-compass:before {
	content: "\e92a";
}
.dw-computer:before {
	content: "\e92b";
}
.dw-computer-1:before {
	content: "\e92c";
}
.dw-agenda:before {
	content: "\e92d";
}
.dw-crop:before {
	content: "\e92e";
}
.dw-crown:before {
	content: "\e92f";
}
.dw-pendrive:before {
	content: "\e930";
}
.dw-calendar-3:before {
	content: "\e931";
}
.dw-calendar-4:before {
	content: "\e932";
}
.dw-ruler:before {
	content: "\e933";
}
.dw-diagram:before {
	content: "\e934";
}
.dw-diamond:before {
	content: "\e935";
}
.dw-book-1:before {
	content: "\e936";
}
.dw-chat-2:before {
	content: "\e937";
}
.dw-chat-3:before {
	content: "\e938";
}
.dw-route:before {
	content: "\e939";
}
.dw-file:before {
	content: "\e93a";
}
.dw-inbox:before {
	content: "\e93b";
}
.dw-download:before {
	content: "\e93c";
}
.dw-cocktail:before {
	content: "\e93d";
}
.dw-dumbbell:before {
	content: "\e93e";
}
.dw-dvd:before {
	content: "\e93f";
}
.dw-edit:before {
	content: "\e940";
}
.dw-edit-1:before {
	content: "\e941";
}
.dw-edit-2:before {
	content: "\e942";
}
.dw-mortarboard:before {
	content: "\e943";
}
.dw-calendar-5:before {
	content: "\e944";
}
.dw-calendar-6:before {
	content: "\e945";
}
.dw-factory:before {
	content: "\e946";
}
.dw-file-1:before {
	content: "\e947";
}
.dw-file-2:before {
	content: "\e948";
}
.dw-filter:before {
	content: "\e949";
}
.dw-filter-1:before {
	content: "\e94a";
}
.dw-fire-extinguisher:before {
	content: "\e94b";
}
.dw-flag:before {
	content: "\e94c";
}
.dw-flame:before {
	content: "\e94d";
}
.dw-flash:before {
	content: "\e94e";
}
.dw-flight:before {
	content: "\e94f";
}
.dw-flight-1:before {
	content: "\e950";
}
.dw-bottle:before {
	content: "\e951";
}
.dw-floppy-disk:before {
	content: "\e952";
}
.dw-flow:before {
	content: "\e953";
}
.dw-focus:before {
	content: "\e954";
}
.dw-folder:before {
	content: "\e955";
}
.dw-dinner:before {
	content: "\e956";
}
.dw-fuel:before {
	content: "\e957";
}
.dw-gamepad:before {
	content: "\e958";
}
.dw-gift:before {
	content: "\e959";
}
.dw-trolley:before {
	content: "\e95a";
}
.dw-package:before {
	content: "\e95b";
}
.dw-hammer:before {
	content: "\e95c";
}
.dw-hammer-1:before {
	content: "\e95d";
}
.dw-headset:before {
	content: "\e95e";
}
.dw-house:before {
	content: "\e95f";
}
.dw-house-1:before {
	content: "\e960";
}
.dw-hook:before {
	content: "\e961";
}
.dw-id-card:before {
	content: "\e962";
}
.dw-id-card-1:before {
	content: "\e963";
}
.dw-idea-1:before {
	content: "\e964";
}
.dw-image:before {
	content: "\e965";
}
.dw-image-1:before {
	content: "\e966";
}
.dw-image-2:before {
	content: "\e967";
}
.dw-inbox-1:before {
	content: "\e968";
}
.dw-inbox-2:before {
	content: "\e969";
}
.dw-inbox-3:before {
	content: "\e96a";
}
.dw-inbox-4:before {
	content: "\e96b";
}
.dw-download-1:before {
	content: "\e96c";
}
.dw-bug:before {
	content: "\e96d";
}
.dw-invoice:before {
	content: "\e96e";
}
.dw-invoice-1:before {
	content: "\e96f";
}
.dw-key:before {
	content: "\e970";
}
.dw-startup:before {
	content: "\e971";
}
.dw-startup-1:before {
	content: "\e972";
}
.dw-library:before {
	content: "\e973";
}
.dw-idea-2:before {
	content: "\e974";
}
.dw-lighthouse:before {
	content: "\e975";
}
.dw-link:before {
	content: "\e976";
}
.dw-pin:before {
	content: "\e977";
}
.dw-pin-1:before {
	content: "\e978";
}
.dw-padlock:before {
	content: "\e979";
}
.dw-magic-wand:before {
	content: "\e97a";
}
.dw-magnifying-glass:before {
	content: "\e97b";
}
.dw-email:before {
	content: "\e97c";
}
.dw-email-1:before {
	content: "\e97d";
}
.dw-map:before {
	content: "\e97e";
}
.dw-pin-2:before {
	content: "\e97f";
}
.dw-map-1:before {
	content: "\e980";
}
.dw-marker:before {
	content: "\e981";
}
.dw-first-aid-kit:before {
	content: "\e982";
}
.dw-mail:before {
	content: "\e983";
}
.dw-chat-4:before {
	content: "\e984";
}
.dw-email-2:before {
	content: "\e985";
}
.dw-chip:before {
	content: "\e986";
}
.dw-chip-1:before {
	content: "\e987";
}
.dw-microphone:before {
	content: "\e988";
}
.dw-microphone-1:before {
	content: "\e989";
}
.dw-smartphone:before {
	content: "\e98a";
}
.dw-cocktail-1:before {
	content: "\e98b";
}
.dw-more:before {
	content: "\e98c";
}
.dw-ticket:before {
	content: "\e98d";
}
.dw-compass-1:before {
	content: "\e98e";
}
.dw-add-file:before {
	content: "\e98f";
}
.dw-nib:before {
	content: "\e990";
}
.dw-notebook:before {
	content: "\e991";
}
.dw-notepad:before {
	content: "\e992";
}
.dw-notepad-1:before {
	content: "\e993";
}
.dw-notepad-2:before {
	content: "\e994";
}
.dw-notification:before {
	content: "\e995";
}
.dw-notification-1:before {
	content: "\e996";
}
.dw-open-book:before {
	content: "\e997";
}
.dw-open-book-1:before {
	content: "\e998";
}
.dw-file-3:before {
	content: "\e999";
}
.dw-paint-bucket:before {
	content: "\e99a";
}
.dw-paint-roller:before {
	content: "\e99b";
}
.dw-paper-plane:before {
	content: "\e99c";
}
.dw-pen:before {
	content: "\e99d";
}
.dw-pencil:before {
	content: "\e99e";
}
.dw-pencil-1:before {
	content: "\e99f";
}
.dw-smartphone-1:before {
	content: "\e9a0";
}
.dw-photo-camera:before {
	content: "\e9a1";
}
.dw-push-pin:before {
	content: "\e9a2";
}
.dw-pin-3:before {
	content: "\e9a3";
}
.dw-push-pin-1:before {
	content: "\e9a4";
}
.dw-push-pin-2:before {
	content: "\e9a5";
}
.dw-video-player:before {
	content: "\e9a6";
}
.dw-swimming-pool:before {
	content: "\e9a7";
}
.dw-presentation:before {
	content: "\e9a8";
}
.dw-presentation-1:before {
	content: "\e9a9";
}
.dw-presentation-2:before {
	content: "\e9aa";
}
.dw-file-4:before {
	content: "\e9ab";
}
.dw-user:before {
	content: "\e9ac";
}
.dw-property:before {
	content: "\e9ad";
}
.dw-wallet:before {
	content: "\e9ae";
}
.dw-radio:before {
	content: "\e9af";
}
.dw-radio-1:before {
	content: "\e9b0";
}
.dw-random:before {
	content: "\e9b1";
}
.dw-open-book-2:before {
	content: "\e9b2";
}
.dw-reload:before {
	content: "\e9b3";
}
.dw-cutlery:before {
	content: "\e9b4";
}
.dw-startup-2:before {
	content: "\e9b5";
}
.dw-router:before {
	content: "\e9b6";
}
.dw-ruler-1:before {
	content: "\e9b7";
}
.dw-safebox:before {
	content: "\e9b8";
}
.dw-hourglass:before {
	content: "\e9b9";
}
.dw-satellite:before {
	content: "\e9ba";
}
.dw-calendar-7:before {
	content: "\e9bb";
}
.dw-monitor:before {
	content: "\e9bc";
}
.dw-monitor-1:before {
	content: "\e9bd";
}
.dw-search:before {
	content: "\e9be";
}
.dw-cursor-1:before {
	content: "\e9bf";
}
.dw-settings:before {
	content: "\e9c0";
}
.dw-share:before {
	content: "\e9c1";
}
.dw-share-1:before {
	content: "\e9c2";
}
.dw-share-2:before {
	content: "\e9c3";
}
.dw-crane:before {
	content: "\e9c4";
}
.dw-ship:before {
	content: "\e9c5";
}
.dw-shopping-cart-1:before {
	content: "\e9c6";
}
.dw-sim-card:before {
	content: "\e9c7";
}
.dw-sofa:before {
	content: "\e9c8";
}
.dw-speaker:before {
	content: "\e9c9";
}
.dw-speaker-1:before {
	content: "\e9ca";
}
.dw-speech:before {
	content: "\e9cb";
}
.dw-stamp:before {
	content: "\e9cc";
}
.dw-stethoscope:before {
	content: "\e9cd";
}
.dw-suitcase:before {
	content: "\e9ce";
}
.dw-syringe:before {
	content: "\e9cf";
}
.dw-tag:before {
	content: "\e9d0";
}
.dw-tag-1:before {
	content: "\e9d1";
}
.dw-target:before {
	content: "\e9d2";
}
.dw-tea:before {
	content: "\e9d3";
}
.dw-chip-2:before {
	content: "\e9d4";
}
.dw-telescope:before {
	content: "\e9d5";
}
.dw-ticket-1:before {
	content: "\e9d6";
}
.dw-ticket-2:before {
	content: "\e9d7";
}
.dw-calendar-8:before {
	content: "\e9d8";
}
.dw-torch:before {
	content: "\e9d9";
}
.dw-train:before {
	content: "\e9da";
}
.dw-delivery-truck:before {
	content: "\e9db";
}
.dw-delivery-truck-1:before {
	content: "\e9dc";
}
.dw-delivery-truck-2:before {
	content: "\e9dd";
}
.dw-trash:before {
	content: "\e9de";
}
.dw-suitcase-1:before {
	content: "\e9df";
}
.dw-television:before {
	content: "\e9e0";
}
.dw-umbrella:before {
	content: "\e9e1";
}
.dw-outbox:before {
	content: "\e9e2";
}
.dw-upload:before {
	content: "\e9e3";
}
.dw-usb:before {
	content: "\e9e4";
}
.dw-user-1:before {
	content: "\e9e5";
}
.dw-video-camera:before {
	content: "\e9e6";
}
.dw-gallery:before {
	content: "\e9e7";
}
.dw-film-reel:before {
	content: "\e9e8";
}
.dw-video-player-1:before {
	content: "\e9e9";
}
.dw-wallet-1:before {
	content: "\e9ea";
}
.dw-watch:before {
	content: "\e9eb";
}
.dw-bottle-1:before {
	content: "\e9ec";
}
.dw-coding-1:before {
	content: "\e9ed";
}
.dw-wifi:before {
	content: "\e9ee";
}
.dw-writing:before {
	content: "\e9ef";
}
.dw-zoom-in:before {
	content: "\e9f0";
}
.dw-zoom-out:before {
	content: "\e9f1";
}
.dw-down-arrow:before {
	content: "\e9f2";
}
.dw-up-arrow:before {
	content: "\e9f3";
}
.dw-left-arrow:before {
	content: "\e9f4";
}
.dw-up-arrow-1:before {
	content: "\e9f5";
}
.dw-shrink:before {
	content: "\e9f6";
}
.dw-skip:before {
	content: "\e9f7";
}
.dw-minimize:before {
	content: "\e9f8";
}
.dw-back:before {
	content: "\e9f9";
}
.dw-diagonal-arrow:before {
	content: "\e9fa";
}
.dw-up-arrow-2:before {
	content: "\e9fb";
}
.dw-diagonal-arrow-1:before {
	content: "\e9fc";
}
.dw-down-arrow-1:before {
	content: "\e9fd";
}
.dw-up-arrow-3:before {
	content: "\e9fe";
}
.dw-return:before {
	content: "\e9ff";
}
.dw-share1:before {
	content: "\ea00";
}
.dw-left-arrow-1:before {
	content: "\ea01";
}
.dw-diagonal-arrow-2:before {
	content: "\ea02";
}
.dw-return-1:before {
	content: "\ea03";
}
.dw-diagonal-arrow-3:before {
	content: "\ea04";
}
.dw-curved-arrow:before {
	content: "\ea05";
}
.dw-resize:before {
	content: "\ea06";
}
.dw-minimize-1:before {
	content: "\ea07";
}
.dw-resize-1:before {
	content: "\ea08";
}
.dw-up-arrow-4:before {
	content: "\ea09";
}
.dw-down-arrow-2:before {
	content: "\ea0a";
}
.dw-return-2:before {
	content: "\ea0b";
}
.dw-return-3:before {
	content: "\ea0c";
}
.dw-return-4:before {
	content: "\ea0d";
}
.dw-resize-2:before {
	content: "\ea0e";
}
.dw-diagonal-arrow-4:before {
	content: "\ea0f";
}
.dw-diagonal-arrow-5:before {
	content: "\ea10";
}
.dw-resize-3:before {
	content: "\ea11";
}
.dw-down-arrow-3:before {
	content: "\ea12";
}
.dw-shrink-1:before {
	content: "\ea13";
}
.dw-diagonal-arrow-6:before {
	content: "\ea14";
}
.dw-diagonal-arrow-7:before {
	content: "\ea15";
}
.dw-diagonal-arrow-8:before {
	content: "\ea16";
}
.dw-minimize-2:before {
	content: "\ea17";
}
.dw-minimize-3:before {
	content: "\ea18";
}
.dw-diagonal-arrow-9:before {
	content: "\ea19";
}
.dw-diagonal-arrow-10:before {
	content: "\ea1a";
}
.dw-diagonal-arrow-11:before {
	content: "\ea1b";
}
.dw-diagonal-arrow-12:before {
	content: "\ea1c";
}
.dw-diagonal-arrow-13:before {
	content: "\ea1d";
}
.dw-diagonal-arrow-14:before {
	content: "\ea1e";
}
.dw-diagonal-arrow-15:before {
	content: "\ea1f";
}
.dw-diagonal-arrow-16:before {
	content: "\ea20";
}
.dw-shrink-2:before {
	content: "\ea21";
}
.dw-diagonal-arrow-17:before {
	content: "\ea22";
}
.dw-up-arrow-5:before {
	content: "\ea23";
}
.dw-left-arrow1:before {
	content: "\ea24";
}
.dw-right-arrow:before {
	content: "\ea25";
}
.dw-right-arrow-1:before {
	content: "\ea26";
}
.dw-expand:before {
	content: "\ea27";
}
.dw-sort:before {
	content: "\ea28";
}
.dw-switch:before {
	content: "\ea29";
}
.dw-expand-1:before {
	content: "\ea2a";
}
.dw-right-arrow-2:before {
	content: "\ea2b";
}
.dw-shuffle:before {
	content: "\ea2c";
}
.dw-left-arrow-11:before {
	content: "\ea2d";
}
.dw-down-arrow1:before {
	content: "\ea2e";
}
.dw-down-arrow-11:before {
	content: "\ea2f";
}
.dw-diagonal-arrow1:before {
	content: "\ea30";
}
.dw-diagonal-arrow-18:before {
	content: "\ea31";
}
.dw-left-arrow-2:before {
	content: "\ea32";
}
.dw-left-arrow-3:before {
	content: "\ea33";
}
.dw-rotate:before {
	content: "\ea34";
}
.dw-down-arrow-21:before {
	content: "\ea35";
}
.dw-right-arrow-3:before {
	content: "\ea36";
}
.dw-diagonal-arrow-21:before {
	content: "\ea37";
}
.dw-repeat:before {
	content: "\ea38";
}
.dw-right-arrow-4:before {
	content: "\ea39";
}
.dw-down-arrow-31:before {
	content: "\ea3a";
}
.dw-up-arrow1:before {
	content: "\ea3b";
}
.dw-up-arrow-11:before {
	content: "\ea3c";
}
.dw-right-arrow-5:before {
	content: "\ea3d";
}
.dw-left-arrow-4:before {
	content: "\ea3e";
}
.dw-up-arrow-21:before {
	content: "\ea3f";
}
.dw-left-arrow-5:before {
	content: "\ea40";
}
.dw-down-arrow-4:before {
	content: "\ea41";
}
.dw-up-arrow-31:before {
	content: "\ea42";
}
.dw-diagonal-arrow-31:before {
	content: "\ea43";
}
.dw-right-arrow-6:before {
	content: "\ea44";
}
.dw-move:before {
	content: "\ea45";
}
.dw-refresh:before {
	content: "\ea46";
}
.dw-diagonal-arrow-41:before {
	content: "\ea47";
}
.dw-down-arrow-5:before {
	content: "\ea48";
}
.dw-repeat-1:before {
	content: "\ea49";
}
.dw-up-arrow-41:before {
	content: "\ea4a";
}
.dw-right-arrow-7:before {
	content: "\ea4b";
}
.dw-right-arrow-8:before {
	content: "\ea4c";
}
.dw-diagonal-arrow-51:before {
	content: "\ea4d";
}
.dw-left-arrow-6:before {
	content: "\ea4e";
}
.dw-down-arrow-6:before {
	content: "\ea4f";
}
.dw-down-arrow-7:before {
	content: "\ea50";
}
.dw-diagonal-arrow-61:before {
	content: "\ea51";
}
.dw-return1:before {
	content: "\ea52";
}
.dw-diagonal-arrow-71:before {
	content: "\ea53";
}
.dw-diagonal-arrow-81:before {
	content: "\ea54";
}
.dw-diagonal-arrow-91:before {
	content: "\ea55";
}
.dw-down-align:before {
	content: "\ea56";
}
.dw-down-align1:before {
	content: "\ea57";
}
.dw-down-align2:before {
	content: "\ea58";
}
.dw-center-align:before {
	content: "\ea59";
}
.dw-center-align1:before {
	content: "\ea5a";
}
.dw-center-align2:before {
	content: "\ea5b";
}
.dw-center-align3:before {
	content: "\ea5c";
}
.dw-align-left:before {
	content: "\ea5d";
}
.dw-align-left1:before {
	content: "\ea5e";
}
.dw-align-left2:before {
	content: "\ea5f";
}
.dw-align-right:before {
	content: "\ea60";
}
.dw-align-right1:before {
	content: "\ea61";
}
.dw-align-right2:before {
	content: "\ea62";
}
.dw-up-align:before {
	content: "\ea63";
}
.dw-up-align1:before {
	content: "\ea64";
}
.dw-up-align2:before {
	content: "\ea65";
}
.dw-bottom:before {
	content: "\ea66";
}
.dw-bottom1:before {
	content: "\ea67";
}
.dw-down-align3:before {
	content: "\ea68";
}
.dw-center-align4:before {
	content: "\ea69";
}
.dw-center-align5:before {
	content: "\ea6a";
}
.dw-center-align6:before {
	content: "\ea6b";
}
.dw-grid:before {
	content: "\ea6c";
}
.dw-rows:before {
	content: "\ea6d";
}
.dw-header:before {
	content: "\ea6e";
}
.dw-inner:before {
	content: "\ea6f";
}
.dw-layout:before {
	content: "\ea70";
}
.dw-layout1:before {
	content: "\ea71";
}
.dw-layout2:before {
	content: "\ea72";
}
.dw-panel:before {
	content: "\ea73";
}
.dw-panel1:before {
	content: "\ea74";
}
.dw-sidebar:before {
	content: "\ea75";
}
.dw-left-align:before {
	content: "\ea76";
}
.dw-no-border:before {
	content: "\ea77";
}
.dw-outer:before {
	content: "\ea78";
}
.dw-header1:before {
	content: "\ea79";
}
.dw-panel2:before {
	content: "\ea7a";
}
.dw-panel3:before {
	content: "\ea7b";
}
.dw-sidebar1:before {
	content: "\ea7c";
}
.dw-right-align:before {
	content: "\ea7d";
}
.dw-grid1:before {
	content: "\ea7e";
}
.dw-table:before {
	content: "\ea7f";
}
.dw-columns:before {
	content: "\ea80";
}
.dw-columns1:before {
	content: "\ea81";
}
.dw-panel4:before {
	content: "\ea82";
}
.dw-panel5:before {
	content: "\ea83";
}
.dw-columns2:before {
	content: "\ea84";
}
.dw-rows1:before {
	content: "\ea85";
}
.dw-rows2:before {
	content: "\ea86";
}
.dw-up-align3:before {
	content: "\ea87";
}
.dw-chat1:before {
	content: "\ea88";
}
.dw-align-center:before {
	content: "\ea89";
}
.dw-align-left3:before {
	content: "\ea8a";
}
.dw-align-right3:before {
	content: "\ea8b";
}
.dw-bold:before {
	content: "\ea8c";
}
.dw-broken-link:before {
	content: "\ea8d";
}
.dw-clear-format:before {
	content: "\ea8e";
}
.dw-clipboard:before {
	content: "\ea8f";
}
.dw-columns3:before {
	content: "\ea90";
}
.dw-file1:before {
	content: "\ea91";
}
.dw-scissors:before {
	content: "\ea92";
}
.dw-size:before {
	content: "\ea93";
}
.dw-chat2:before {
	content: "\ea94";
}
.dw-edit1:before {
	content: "\ea95";
}
.dw-font:before {
	content: "\ea96";
}
.dw-grammar:before {
	content: "\ea97";
}
.dw-highlight:before {
	content: "\ea98";
}
.dw-idea1:before {
	content: "\ea99";
}
.dw-font1:before {
	content: "\ea9a";
}
.dw-italic:before {
	content: "\ea9b";
}
.dw-left-indent:before {
	content: "\ea9c";
}
.dw-line-spacing:before {
	content: "\ea9d";
}
.dw-link1:before {
	content: "\ea9e";
}
.dw-link2:before {
	content: "\ea9f";
}
.dw-list:before {
	content: "\eaa0";
}
.dw-more1:before {
	content: "\eaa1";
}
.dw-note:before {
	content: "\eaa2";
}
.dw-note1:before {
	content: "\eaa3";
}
.dw-note2:before {
	content: "\eaa4";
}
.dw-list1:before {
	content: "\eaa5";
}
.dw-list2:before {
	content: "\eaa6";
}
.dw-page:before {
	content: "\eaa7";
}
.dw-page1:before {
	content: "\eaa8";
}
.dw-paperclip:before {
	content: "\eaa9";
}
.dw-paragraph:before {
	content: "\eaaa";
}
.dw-paragraph1:before {
	content: "\eaab";
}
.dw-paste:before {
	content: "\eaac";
}
.dw-note3:before {
	content: "\eaad";
}
.dw-print:before {
	content: "\eaae";
}
.dw-redo:before {
	content: "\eaaf";
}
.dw-right-indent:before {
	content: "\eab0";
}
.dw-diskette:before {
	content: "\eab1";
}
.dw-search1:before {
	content: "\eab2";
}
.dw-size1:before {
	content: "\eab3";
}
.dw-pin1:before {
	content: "\eab4";
}
.dw-table1:before {
	content: "\eab5";
}
.dw-text:before {
	content: "\eab6";
}
.dw-text1:before {
	content: "\eab7";
}
.dw-underline:before {
	content: "\eab8";
}
.dw-undo:before {
	content: "\eab9";
}
.dw-down-arrow2:before {
	content: "\eaba";
}
.dw-up-arrow2:before {
	content: "\eabb";
}
.dw-left-arrow2:before {
	content: "\eabc";
}
.dw-right-arrow1:before {
	content: "\eabd";
}
.dw-diagonal-arrow2:before {
	content: "\eabe";
}
.dw-diagonal-arrow-19:before {
	content: "\eabf";
}
.dw-diagonal-arrow-22:before {
	content: "\eac0";
}
.dw-diagonal-arrow-32:before {
	content: "\eac1";
}
.dw-double-arrow:before {
	content: "\eac2";
}
.dw-double-arrow-1:before {
	content: "\eac3";
}
.dw-bus:before {
	content: "\eac4";
}
.dw-truck:before {
	content: "\eac5";
}
.dw-ambulance:before {
	content: "\eac6";
}
.dw-helicopters:before {
	content: "\eac7";
}
.dw-sailboat1:before {
	content: "\eac8";
}
.dw-cable-car-cabin:before {
	content: "\eac9";
}
.dw-shop:before {
	content: "\eaca";
}
.dw-groceries-store:before {
	content: "\eacb";
}
.dw-pagoda:before {
	content: "\eacc";
}
.dw-coffee-cup:before {
	content: "\eacd";
}
.dw-sort1:before {
	content: "\eace";
}
.dw-food-cart:before {
	content: "\eacf";
}
.dw-mosque:before {
	content: "\ead0";
}
.dw-building1:before {
	content: "\ead1";
}
.dw-police-box:before {
	content: "\ead2";
}
.dw-caravan:before {
	content: "\ead3";
}
.dw-school:before {
	content: "\ead4";
}
.dw-kayak:before {
	content: "\ead5";
}
.dw-skyscraper:before {
	content: "\ead6";
}
.dw-building-1:before {
	content: "\ead7";
}
.dw-bonfire:before {
	content: "\ead8";
}
.dw-exchange:before {
	content: "\ead9";
}
.dw-tent:before {
	content: "\eada";
}
.dw-house1:before {
	content: "\eadb";
}
.dw-hospital:before {
	content: "\eadc";
}
.dw-factory1:before {
	content: "\eadd";
}
.dw-city-hall:before {
	content: "\eade";
}
.dw-city:before {
	content: "\eadf";
}
.dw-bridge:before {
	content: "\eae0";
}
.dw-ferris-wheel:before {
	content: "\eae1";
}
.dw-billboard:before {
	content: "\eae2";
}
.dw-phone-booth:before {
	content: "\eae3";
}
.dw-expand1:before {
	content: "\eae4";
}
.dw-bus-stop:before {
	content: "\eae5";
}
.dw-turn-right:before {
	content: "\eae6";
}
.dw-street-light:before {
	content: "\eae7";
}
.dw-hotel:before {
	content: "\eae8";
}
.dw-obelisk:before {
	content: "\eae9";
}
.dw-electric-tower:before {
	content: "\eaea";
}
.dw-signboard:before {
	content: "\eaeb";
}
.dw-traffic-light:before {
	content: "\eaec";
}
.dw-hydrant:before {
	content: "\eaed";
}
.dw-bench:before {
	content: "\eaee";
}
.dw-move1:before {
	content: "\eaef";
}
.dw-fountain:before {
	content: "\eaf0";
}
.dw-panels:before {
	content: "\eaf1";
}
.dw-mountain:before {
	content: "\eaf2";
}
.dw-barn:before {
	content: "\eaf3";
}
.dw-desert:before {
	content: "\eaf4";
}
.dw-trees:before {
	content: "\eaf5";
}
.dw-house-11:before {
	content: "\eaf6";
}
.dw-sun-umbrella:before {
	content: "\eaf7";
}
.dw-island:before {
	content: "\eaf8";
}
.dw-waterfall:before {
	content: "\eaf9";
}
.dw-expand-11:before {
	content: "\eafa";
}
.dw-windmill:before {
	content: "\eafb";
}
.dw-helm:before {
	content: "\eafc";
}
.dw-anchor:before {
	content: "\eafd";
}
.dw-umbrella1:before {
	content: "\eafe";
}
.dw-polaroids:before {
	content: "\eaff";
}
.dw-lifesaver:before {
	content: "\eb00";
}
.dw-suitcase1:before {
	content: "\eb01";
}
.dw-earth-globe:before {
	content: "\eb02";
}
.dw-flight1:before {
	content: "\eb03";
}
.dw-heart:before {
	content: "\eb04";
}
.dw-compress:before {
	content: "\eb05";
}
.dw-download1:before {
	content: "\eb06";
}
.dw-upload1:before {
	content: "\eb07";
}
.dw-search2:before {
	content: "\eb08";
}
.dw-image1:before {
	content: "\eb09";
}
.dw-trash1:before {
	content: "\eb0a";
}
.dw-attachment:before {
	content: "\eb0b";
}
.dw-edit2:before {
	content: "\eb0c";
}
.dw-email1:before {
	content: "\eb0d";
}
.dw-shopping-cart1:before {
	content: "\eb0e";
}
.dw-user1:before {
	content: "\eb0f";
}
.dw-curve-arrow:before {
	content: "\eb10";
}
.dw-add-user:before {
	content: "\eb11";
}
.dw-cloud:before {
	content: "\eb12";
}
.dw-bug1:before {
	content: "\eb13";
}
.dw-fire:before {
	content: "\eb14";
}
.dw-copyright:before {
	content: "\eb15";
}
.dw-star:before {
	content: "\eb16";
}
.dw-star-1:before {
	content: "\eb17";
}
.dw-notification1:before {
	content: "\eb18";
}
.dw-notification-11:before {
	content: "\eb19";
}
.dw-volume:before {
	content: "\eb1a";
}
.dw-curve-arrow-1:before {
	content: "\eb1b";
}
.dw-list3:before {
	content: "\eb1c";
}
.dw-check:before {
	content: "\eb1d";
}
.dw-expand-2:before {
	content: "\eb1e";
}
.dw-subtitles:before {
	content: "\eb1f";
}
.dw-paper-plane1:before {
	content: "\eb20";
}
.dw-zoom-in1:before {
	content: "\eb21";
}
.dw-zoom-out1:before {
	content: "\eb22";
}
.dw-settings1:before {
	content: "\eb23";
}
.dw-file2:before {
	content: "\eb24";
}
.dw-file-11:before {
	content: "\eb25";
}
.dw-curved-arrow1:before {
	content: "\eb26";
}
.dw-add-file1:before {
	content: "\eb27";
}
.dw-file-21:before {
	content: "\eb28";
}
.dw-file-31:before {
	content: "\eb29";
}
.dw-edit-file:before {
	content: "\eb2a";
}
.dw-audio-file:before {
	content: "\eb2b";
}
.dw-image-11:before {
	content: "\eb2c";
}
.dw-video-file:before {
	content: "\eb2d";
}
.dw-file-41:before {
	content: "\eb2e";
}
.dw-video-camera1:before {
	content: "\eb2f";
}
.dw-video-camera-1:before {
	content: "\eb30";
}
.dw-curve-arrow-2:before {
	content: "\eb31";
}
.dw-phone-call:before {
	content: "\eb32";
}
.dw-phone-call-1:before {
	content: "\eb33";
}
.dw-photo-camera1:before {
	content: "\eb34";
}
.dw-wall-clock1:before {
	content: "\eb35";
}
.dw-refresh1:before {
	content: "\eb36";
}
.dw-padlock1:before {
	content: "\eb37";
}
.dw-open-padlock:before {
	content: "\eb38";
}
.dw-price-tag:before {
	content: "\eb39";
}
.dw-inbox1:before {
	content: "\eb3a";
}
.dw-outbox1:before {
	content: "\eb3b";
}
.dw-down-chevron:before {
	content: "\eb3c";
}
.dw-cancel:before {
	content: "\eb3d";
}
.dw-warning:before {
	content: "\eb3e";
}
.dw-question:before {
	content: "\eb3f";
}
.dw-chat3:before {
	content: "\eb40";
}
.dw-calendar1:before {
	content: "\eb41";
}
.dw-folder1:before {
	content: "\eb42";
}
.dw-like:before {
	content: "\eb43";
}
.dw-thumb-down:before {
	content: "\eb44";
}
.dw-filter1:before {
	content: "\eb45";
}
.dw-worldwide:before {
	content: "\eb46";
}
.dw-up-chevron:before {
	content: "\eb47";
}
.dw-smartphone1:before {
	content: "\eb48";
}
.dw-tablet:before {
	content: "\eb49";
}
.dw-personal-computer:before {
	content: "\eb4a";
}
.dw-diskette1:before {
	content: "\eb4b";
}
.dw-logout:before {
	content: "\eb4c";
}
.dw-menu:before {
	content: "\eb4d";
}
.dw-menu-1:before {
	content: "\eb4e";
}
.dw-menu-2:before {
	content: "\eb4f";
}
.dw-credit-card:before {
	content: "\eb50";
}
.dw-eye:before {
	content: "\eb51";
}
.dw-left-chevron:before {
	content: "\eb52";
}
.dw-hide:before {
	content: "\eb53";
}
.dw-crown1:before {
	content: "\eb54";
}
.dw-paint-palette:before {
	content: "\eb55";
}
.dw-undo1:before {
	content: "\eb56";
}
.dw-redo1:before {
	content: "\eb57";
}
.dw-opacity:before {
	content: "\eb58";
}
.dw-copy:before {
	content: "\eb59";
}
.dw-layers:before {
	content: "\eb5a";
}
.dw-sheet:before {
	content: "\eb5b";
}
.dw-shield:before {
	content: "\eb5c";
}
.dw-right-chevron:before {
	content: "\eb5d";
}
.dw-quotation:before {
	content: "\eb5e";
}
.dw-cookie:before {
	content: "\eb5f";
}
.dw-link3:before {
	content: "\eb60";
}
.dw-book1:before {
	content: "\eb61";
}
.dw-coupon:before {
	content: "\eb62";
}
.dw-cursor1:before {
	content: "\eb63";
}
.dw-cursor-11:before {
	content: "\eb64";
}
.dw-suitcase-11:before {
	content: "\eb65";
}
.dw-group:before {
	content: "\eb66";
}
.dw-conference:before {
	content: "\eb67";
}
.dw-down-chevron-1:before {
	content: "\eb68";
}
.dw-deal:before {
	content: "\eb69";
}
.dw-id-card1:before {
	content: "\eb6a";
}
.dw-human-resources:before {
	content: "\eb6b";
}
.dw-goal:before {
	content: "\eb6c";
}
.dw-meeting:before {
	content: "\eb6d";
}
.dw-elderly:before {
	content: "\eb6e";
}
.dw-insurance:before {
	content: "\eb6f";
}
.dw-user-11:before {
	content: "\eb70";
}
.dw-time-management:before {
	content: "\eb71";
}
.dw-strategy:before {
	content: "\eb72";
}
.dw-up-chevron-1:before {
	content: "\eb73";
}
.dw-workflow:before {
	content: "\eb74";
}
.dw-pyramid-chart:before {
	content: "\eb75";
}
.dw-profits:before {
	content: "\eb76";
}
.dw-loss:before {
	content: "\eb77";
}
.dw-bar-chart:before {
	content: "\eb78";
}
.dw-profits-1:before {
	content: "\eb79";
}
.dw-loss-1:before {
	content: "\eb7a";
}
.dw-pie-chart:before {
	content: "\eb7b";
}
.dw-bar-chart-1:before {
	content: "\eb7c";
}
.dw-agenda1:before {
	content: "\eb7d";
}
.dw-left-chevron-1:before {
	content: "\eb7e";
}
.dw-flower:before {
	content: "\eb7f";
}
.dw-pamela:before {
	content: "\eb80";
}
.dw-branch:before {
	content: "\eb81";
}
.dw-winter:before {
	content: "\eb82";
}
.dw-rainy:before {
	content: "\eb83";
}
.dw-rainy-1:before {
	content: "\eb84";
}
.dw-rainy-2:before {
	content: "\eb85";
}
.dw-umbrella-1:before {
	content: "\eb86";
}
.dw-cloud-1:before {
	content: "\eb87";
}
.dw-clouds:before {
	content: "\eb88";
}
.dw-right-chevron-1:before {
	content: "\eb89";
}
.dw-cloudy-night:before {
	content: "\eb8a";
}
.dw-sun:before {
	content: "\eb8b";
}
.dw-thermometer:before {
	content: "\eb8c";
}
.dw-thermometer-1:before {
	content: "\eb8d";
}
.dw-thermometer-2:before {
	content: "\eb8e";
}
.dw-thermometer-3:before {
	content: "\eb8f";
}
.dw-thermometer-4:before {
	content: "\eb90";
}
.dw-drop:before {
	content: "\eb91";
}
.dw-windy:before {
	content: "\eb92";
}
.dw-wind:before {
	content: "\eb93";
}
.dw-shuffle1:before {
	content: "\eb94";
}
.dw-wind-1:before {
	content: "\eb95";
}
.dw-wind-2:before {
	content: "\eb96";
}
.dw-snow:before {
	content: "\eb97";
}
.dw-snowflake:before {
	content: "\eb98";
}
.dw-snowflake-1:before {
	content: "\eb99";
}
.dw-windy-1:before {
	content: "\eb9a";
}
.dw-hail:before {
	content: "\eb9b";
}
.dw-rainbow:before {
	content: "\eb9c";
}
.dw-rainbow-1:before {
	content: "\eb9d";
}
.dw-rainbow-2:before {
	content: "\eb9e";
}
.dw-recycle:before {
	content: "\eb9f";
}
.dw-rainbow-3:before {
	content: "\eba0";
}
.dw-storm:before {
	content: "\eba1";
}
.dw-bolt:before {
	content: "\eba2";
}
.dw-cloudy:before {
	content: "\eba3";
}
.dw-cloudy-1:before {
	content: "\eba4";
}
.dw-cloudy-2:before {
	content: "\eba5";
}
.dw-eclipse:before {
	content: "\eba6";
}
.dw-moon-phase:before {
	content: "\eba7";
}
.dw-moon-phase-1:before {
	content: "\eba8";
}
.dw-moon-phase-2:before {
	content: "\eba9";
}
.dw-split:before {
	content: "\ebaa";
}
.dw-moon-phase-3:before {
	content: "\ebab";
}
.dw-moon-phase-4:before {
	content: "\ebac";
}
.dw-moon-phase-5:before {
	content: "\ebad";
}
.dw-half-moon:before {
	content: "\ebae";
}
.dw-hurricane:before {
	content: "\ebaf";
}
.dw-foggy:before {
	content: "\ebb0";
}
.dw-co2:before {
	content: "\ebb1";
}
.dw-humidity:before {
	content: "\ebb2";
}
.dw-tornado:before {
	content: "\ebb3";
}
.dw-basketball:before {
	content: "\ebb4";
}
.dw-merge:before {
	content: "\ebb5";
}
.dw-baseball:before {
	content: "\ebb6";
}
.dw-football:before {
	content: "\ebb7";
}
.dw-volleyball:before {
	content: "\ebb8";
}
.dw-rugby-ball:before {
	content: "\ebb9";
}
.dw-tennis:before {
	content: "\ebba";
}
.dw-bowling:before {
	content: "\ebbb";
}
.dw-ice-skate:before {
	content: "\ebbc";
}
.dw-roller-skate:before {
	content: "\ebbd";
}
.dw-skateboard:before {
	content: "\ebbe";
}
.dw-karate:before {
	content: "\ebbf";
}
.dw-u-turn:before {
	content: "\ebc0";
}
.dw-ice-hockey:before {
	content: "\ebc1";
}
.dw-golf:before {
	content: "\ebc2";
}
.dw-boxing:before {
	content: "\ebc3";
}
.dw-surfboard:before {
	content: "\ebc4";
}
.dw-dart:before {
	content: "\ebc5";
}
.dw-goal-1:before {
	content: "\ebc6";
}
.dw-badminton:before {
	content: "\ebc7";
}
.dw-ping-pong:before {
	content: "\ebc8";
}
.dw-racket:before {
	content: "\ebc9";
}
.dw-soccer-field:before {
	content: "\ebca";
}
.dw-split-1:before {
	content: "\ebcb";
}
.dw-basketball-court:before {
	content: "\ebcc";
}
.dw-tennis-court:before {
	content: "\ebcd";
}
.dw-american-football:before {
	content: "\ebce";
}
.dw-mountain-1:before {
	content: "\ebcf";
}
.dw-mountain-2:before {
	content: "\ebd0";
}
.dw-mountain-3:before {
	content: "\ebd1";
}
.dw-night:before {
	content: "\ebd2";
}
.dw-rainbow-4:before {
	content: "\ebd3";
}
.dw-barn-1:before {
	content: "\ebd4";
}
.dw-trees-1:before {
	content: "\ebd5";
}
.dw-split-2:before {
	content: "\ebd6";
}
.dw-desert-1:before {
	content: "\ebd7";
}
.dw-road:before {
	content: "\ebd8";
}
.dw-sunrise:before {
	content: "\ebd9";
}
.dw-sunset:before {
	content: "\ebda";
}
.dw-beach-house:before {
	content: "\ebdb";
}
.dw-sunbed:before {
	content: "\ebdc";
}
.dw-island-1:before {
	content: "\ebdd";
}
.dw-sailboat-1:before {
	content: "\ebde";
}
.dw-waterfall-1:before {
	content: "\ebdf";
}
.dw-windmill-1:before {
	content: "\ebe0";
}
.dw-triple-arrows:before {
	content: "\ebe1";
}
.dw-plant:before {
	content: "\ebe2";
}
.dw-flower-1:before {
	content: "\ebe3";
}
.dw-sprout:before {
	content: "\ebe4";
}
.dw-plant-1:before {
	content: "\ebe5";
}
.dw-wheat:before {
	content: "\ebe6";
}
.dw-harvest:before {
	content: "\ebe7";
}
.dw-rose:before {
	content: "\ebe8";
}
.dw-poppy:before {
	content: "\ebe9";
}
.dw-tulip:before {
	content: "\ebea";
}
.dw-pinwheel:before {
	content: "\ebeb";
}
.dw-happy:before {
	content: "\ebec";
}
.dw-fruit-tree:before {
	content: "\ebed";
}
.dw-tree:before {
	content: "\ebee";
}
.dw-pine:before {
	content: "\ebef";
}
.dw-pine-1:before {
	content: "\ebf0";
}
.dw-palm-tree:before {
	content: "\ebf1";
}
.dw-cactus:before {
	content: "\ebf2";
}
.dw-recycle-1:before {
	content: "\ebf3";
}
.dw-sprout-1:before {
	content: "\ebf4";
}
.dw-save-water:before {
	content: "\ebf5";
}
.dw-faucet:before {
	content: "\ebf6";
}
.dw-sad:before {
	content: "\ebf7";
}
.dw-ecology:before {
	content: "\ebf8";
}
.dw-cat:before {
	content: "\ebf9";
}
.dw-dog:before {
	content: "\ebfa";
}
.dw-horse:before {
	content: "\ebfb";
}
.dw-bird:before {
	content: "\ebfc";
}
.dw-rabbit:before {
	content: "\ebfd";
}
.dw-butterfly:before {
	content: "\ebfe";
}
.dw-deer:before {
	content: "\ebff";
}
.dw-sheep:before {
	content: "\ec00";
}
.dw-monkey:before {
	content: "\ec01";
}
.dw-meh:before {
	content: "\ec02";
}
.dw-burger:before {
	content: "\ec03";
}
.dw-pizza:before {
	content: "\ec04";
}
.dw-sandwich:before {
	content: "\ec05";
}
.dw-hot-dog:before {
	content: "\ec06";
}
.dw-chicken-leg:before {
	content: "\ec07";
}
.dw-french-fries:before {
	content: "\ec08";
}
.dw-tomato:before {
	content: "\ec09";
}
.dw-onion:before {
	content: "\ec0a";
}
.dw-bell-pepper:before {
	content: "\ec0b";
}
.dw-cabbage:before {
	content: "\ec0c";
}
.dw-support:before {
	content: "\ec0d";
}
.dw-corn:before {
	content: "\ec0e";
}
.dw-pumpkin:before {
	content: "\ec0f";
}
.dw-eggplant:before {
	content: "\ec10";
}
.dw-carrot:before {
	content: "\ec11";
}
.dw-broccoli:before {
	content: "\ec12";
}
.dw-avocado:before {
	content: "\ec13";
}
.dw-pear:before {
	content: "\ec14";
}
.dw-strawberry:before {
	content: "\ec15";
}
.dw-pineapple:before {
	content: "\ec16";
}
.dw-orange:before {
	content: "\ec17";
}
.dw-support-1:before {
	content: "\ec18";
}
.dw-banana:before {
	content: "\ec19";
}
.dw-watermelon:before {
	content: "\ec1a";
}
.dw-grapes:before {
	content: "\ec1b";
}
.dw-cherry:before {
	content: "\ec1c";
}
.dw-bread:before {
	content: "\ec1d";
}
.dw-steak:before {
	content: "\ec1e";
}
.dw-cheese:before {
	content: "\ec1f";
}
.dw-fried-egg:before {
	content: "\ec20";
}
.dw-soup:before {
	content: "\ec21";
}
.dw-salad:before {
	content: "\ec22";
}
.dw-information:before {
	content: "\ec23";
}
.dw-fish:before {
	content: "\ec24";
}
.dw-shrimp:before {
	content: "\ec25";
}
.dw-crab:before {
	content: "\ec26";
}
.dw-cake:before {
	content: "\ec27";
}
.dw-muffin:before {
	content: "\ec28";
}
.dw-pancakes:before {
	content: "\ec29";
}
.dw-water:before {
	content: "\ec2a";
}
.dw-milk:before {
	content: "\ec2b";
}
.dw-soda:before {
	content: "\ec2c";
}
.dw-wine:before {
	content: "\ec2d";
}
.dw-question-1:before {
	content: "\ec2e";
}
.dw-energy-drink:before {
	content: "\ec2f";
}
.dw-tea-cup:before {
	content: "\ec30";
}
.dw-coffee-cup-1:before {
	content: "\ec31";
}
.dw-beer:before {
	content: "\ec32";
}
.dw-warning-1:before {
	content: "\ec33";
}
.dw-chat-11:before {
	content: "\ec34";
}
.dw-calendar-11:before {
	content: "\ec35";
}
.dw-help:before {
	content: "\ec36";
}
.dw-cone:before {
	content: "\ec37";
}
.dw-counterclockwise:before {
	content: "\ec38";
}
.dw-headphones:before {
	content: "\ec39";
}
.dw-key1:before {
	content: "\ec3a";
}
.dw-server:before {
	content: "\ec3b";
}
.dw-24-hours:before {
	content: "\ec3c";
}
.dw-target1:before {
	content: "\ec3d";
}
.dw-target-1:before {
	content: "\ec3e";
}
.dw-target-2:before {
	content: "\ec3f";
}
.dw-pin2:before {
	content: "\ec40";
}
.dw-pin-11:before {
	content: "\ec41";
}
.dw-pin-21:before {
	content: "\ec42";
}
.dw-pin-31:before {
	content: "\ec43";
}
.dw-pin-4:before {
	content: "\ec44";
}
.dw-pin-5:before {
	content: "\ec45";
}
.dw-flag1:before {
	content: "\ec46";
}
.dw-pin-6:before {
	content: "\ec47";
}
.dw-pin-7:before {
	content: "\ec48";
}
.dw-finger:before {
	content: "\ec49";
}
.dw-position:before {
	content: "\ec4a";
}
.dw-position-1:before {
	content: "\ec4b";
}
.dw-compass1:before {
	content: "\ec4c";
}
.dw-wind-rose:before {
	content: "\ec4d";
}
.dw-cursor-2:before {
	content: "\ec4e";
}
.dw-route1:before {
	content: "\ec4f";
}
.dw-distance:before {
	content: "\ec50";
}
.dw-pin-8:before {
	content: "\ec51";
}
.dw-worldwide-1:before {
	content: "\ec52";
}
.dw-internet:before {
	content: "\ec53";
}
.dw-internet-1:before {
	content: "\ec54";
}
.dw-internet-2:before {
	content: "\ec55";
}
.dw-map1:before {
	content: "\ec56";
}
.dw-map-11:before {
	content: "\ec57";
}
.dw-map-2:before {
	content: "\ec58";
}
.dw-map-3:before {
	content: "\ec59";
}
.dw-map-4:before {
	content: "\ec5a";
}
.dw-map-5:before {
	content: "\ec5b";
}
.dw-map-6:before {
	content: "\ec5c";
}
.dw-map-7:before {
	content: "\ec5d";
}
.dw-panel6:before {
	content: "\ec5e";
}
.dw-bookmark1:before {
	content: "\ec5f";
}
.dw-wifi1:before {
	content: "\ec60";
}
.dw-car:before {
	content: "\ec61";
}
.dw-taxi:before {
	content: "\ec62";
}
.dw-flight-11:before {
	content: "\ec63";
}
.dw-boat:before {
	content: "\ec64";
}
.dw-rocket:before {
	content: "\ec65";
}
.dw-metro:before {
	content: "\ec66";
}
.dw-train1:before {
	content: "\ec67";
}
.dw-tram:before {
	content: "\ec68";
}
.dw-motorcycle:before {
	content: "\ec69";
}
.dw-bicycle:before {
	content: "\ec6a";
}
.dw-add-file2:before {
	content: "\ec6b";
}
.dw-add-file-1:before {
	content: "\ec6c";
}
.dw-folder2:before {
	content: "\ec6d";
}
.dw-folder-1:before {
	content: "\ec6e";
}
.dw-folder-2:before {
	content: "\ec6f";
}
.dw-add-file-2:before {
	content: "\ec70";
}
.dw-file3:before {
	content: "\ec71";
}
.dw-file-12:before {
	content: "\ec72";
}
.dw-folder-3:before {
	content: "\ec73";
}
.dw-folder-4:before {
	content: "\ec74";
}
.dw-folder-5:before {
	content: "\ec75";
}
.dw-file-22:before {
	content: "\ec76";
}
.dw-file-32:before {
	content: "\ec77";
}
.dw-file-42:before {
	content: "\ec78";
}
.dw-folder-6:before {
	content: "\ec79";
}
.dw-folder-7:before {
	content: "\ec7a";
}
.dw-folder-8:before {
	content: "\ec7b";
}
.dw-file-5:before {
	content: "\ec7c";
}
.dw-file-6:before {
	content: "\ec7d";
}
.dw-file-7:before {
	content: "\ec7e";
}
.dw-folder-9:before {
	content: "\ec7f";
}
.dw-folder-10:before {
	content: "\ec80";
}
.dw-folder-11:before {
	content: "\ec81";
}
.dw-file-8:before {
	content: "\ec82";
}
.dw-file-9:before {
	content: "\ec83";
}
.dw-file-10:before {
	content: "\ec84";
}
.dw-folder-12:before {
	content: "\ec85";
}
.dw-folder-13:before {
	content: "\ec86";
}
.dw-folder-14:before {
	content: "\ec87";
}
.dw-file-111:before {
	content: "\ec88";
}
.dw-analytics:before {
	content: "\ec89";
}
.dw-analytics-1:before {
	content: "\ec8a";
}
.dw-folder-15:before {
	content: "\ec8b";
}
.dw-folder-16:before {
	content: "\ec8c";
}
.dw-folder-17:before {
	content: "\ec8d";
}
.dw-analytics-2:before {
	content: "\ec8e";
}
.dw-file-121:before {
	content: "\ec8f";
}
.dw-file-13:before {
	content: "\ec90";
}
.dw-folder-18:before {
	content: "\ec91";
}
.dw-folder-19:before {
	content: "\ec92";
}
.dw-folder-20:before {
	content: "\ec93";
}
.dw-file-14:before {
	content: "\ec94";
}
.dw-file-15:before {
	content: "\ec95";
}
.dw-file-16:before {
	content: "\ec96";
}
.dw-folder-21:before {
	content: "\ec97";
}
.dw-folder-22:before {
	content: "\ec98";
}
.dw-folder-23:before {
	content: "\ec99";
}
.dw-file-17:before {
	content: "\ec9a";
}
.dw-file-18:before {
	content: "\ec9b";
}
.dw-file-19:before {
	content: "\ec9c";
}
.dw-folder-24:before {
	content: "\ec9d";
}
.dw-folder-25:before {
	content: "\ec9e";
}
.dw-folder-26:before {
	content: "\ec9f";
}
.dw-file-20:before {
	content: "\eca0";
}
.dw-file-211:before {
	content: "\eca1";
}
.dw-file-221:before {
	content: "\eca2";
}
.dw-folder-27:before {
	content: "\eca3";
}
.dw-folder-28:before {
	content: "\eca4";
}
.dw-folder-29:before {
	content: "\eca5";
}
.dw-file-23:before {
	content: "\eca6";
}
.dw-file-24:before {
	content: "\eca7";
}
.dw-file-25:before {
	content: "\eca8";
}
.dw-folder-30:before {
	content: "\eca9";
}
.dw-folder-31:before {
	content: "\ecaa";
}
.dw-folder-32:before {
	content: "\ecab";
}
.dw-file-26:before {
	content: "\ecac";
}
.dw-file-27:before {
	content: "\ecad";
}
.dw-file-28:before {
	content: "\ecae";
}
.dw-folder-33:before {
	content: "\ecaf";
}
.dw-folder-34:before {
	content: "\ecb0";
}
.dw-folder-35:before {
	content: "\ecb1";
}
.dw-file-29:before {
	content: "\ecb2";
}
.dw-file-30:before {
	content: "\ecb3";
}
.dw-file-311:before {
	content: "\ecb4";
}
.dw-folder-36:before {
	content: "\ecb5";
}
.dw-folder-37:before {
	content: "\ecb6";
}
.dw-folder-38:before {
	content: "\ecb7";
}
.dw-file-321:before {
	content: "\ecb8";
}
.dw-file-33:before {
	content: "\ecb9";
}
.dw-file-34:before {
	content: "\ecba";
}
.dw-folder-39:before {
	content: "\ecbb";
}
.dw-folder-40:before {
	content: "\ecbc";
}
.dw-folder-41:before {
	content: "\ecbd";
}
.dw-file-35:before {
	content: "\ecbe";
}
.dw-file-36:before {
	content: "\ecbf";
}
.dw-file-37:before {
	content: "\ecc0";
}
.dw-folder-42:before {
	content: "\ecc1";
}
.dw-folder-43:before {
	content: "\ecc2";
}
.dw-folder-44:before {
	content: "\ecc3";
}
.dw-file-38:before {
	content: "\ecc4";
}
.dw-file-39:before {
	content: "\ecc5";
}
.dw-file-40:before {
	content: "\ecc6";
}
.dw-folder-45:before {
	content: "\ecc7";
}
.dw-folder-46:before {
	content: "\ecc8";
}
.dw-folder-47:before {
	content: "\ecc9";
}
.dw-file-411:before {
	content: "\ecca";
}
.dw-file-421:before {
	content: "\eccb";
}
.dw-file-43:before {
	content: "\eccc";
}
.dw-folder-48:before {
	content: "\eccd";
}
.dw-folder-49:before {
	content: "\ecce";
}
.dw-folder-50:before {
	content: "\eccf";
}
.dw-file-44:before {
	content: "\ecd0";
}
.dw-file-45:before {
	content: "\ecd1";
}
.dw-file-46:before {
	content: "\ecd2";
}
.dw-folder-51:before {
	content: "\ecd3";
}
.dw-folder-52:before {
	content: "\ecd4";
}
.dw-folder-53:before {
	content: "\ecd5";
}
.dw-file-47:before {
	content: "\ecd6";
}
.dw-file-48:before {
	content: "\ecd7";
}
.dw-file-49:before {
	content: "\ecd8";
}
.dw-folder-54:before {
	content: "\ecd9";
}
.dw-folder-55:before {
	content: "\ecda";
}
.dw-folder-56:before {
	content: "\ecdb";
}
.dw-file-50:before {
	content: "\ecdc";
}
.dw-file-51:before {
	content: "\ecdd";
}
.dw-file-52:before {
	content: "\ecde";
}
.dw-folder-57:before {
	content: "\ecdf";
}
.dw-folder-58:before {
	content: "\ece0";
}
.dw-folder-59:before {
	content: "\ece1";
}
.dw-file-53:before {
	content: "\ece2";
}
.dw-folder-60:before {
	content: "\ece3";
}
.dw-file-54:before {
	content: "\ece4";
}
.dw-file-55:before {
	content: "\ece5";
}
.dw-folder-61:before {
	content: "\ece6";
}
.dw-folder-62:before {
	content: "\ece7";
}
.dw-folder-63:before {
	content: "\ece8";
}
.dw-file-56:before {
	content: "\ece9";
}
.dw-file-57:before {
	content: "\ecea";
}
.dw-file-58:before {
	content: "\eceb";
}
.dw-folder-64:before {
	content: "\ecec";
}
.dw-folder-65:before {
	content: "\eced";
}
.dw-folder-66:before {
	content: "\ecee";
}
.dw-file-59:before {
	content: "\ecef";
}
.dw-file-60:before {
	content: "\ecf0";
}
.dw-file-61:before {
	content: "\ecf1";
}
.dw-folder-67:before {
	content: "\ecf2";
}
.dw-folder-68:before {
	content: "\ecf3";
}
.dw-folder-69:before {
	content: "\ecf4";
}
.dw-file-62:before {
	content: "\ecf5";
}
.dw-file-63:before {
	content: "\ecf6";
}
.dw-file-64:before {
	content: "\ecf7";
}
.dw-folder-70:before {
	content: "\ecf8";
}
.dw-folder-71:before {
	content: "\ecf9";
}
.dw-folder-72:before {
	content: "\ecfa";
}
.dw-file-65:before {
	content: "\ecfb";
}
.dw-file-66:before {
	content: "\ecfc";
}
.dw-file-67:before {
	content: "\ecfd";
}
.dw-folder-73:before {
	content: "\ecfe";
}
.dw-folder-74:before {
	content: "\ecff";
}
.dw-folder-75:before {
	content: "\ed00";
}
.dw-file-68:before {
	content: "\ed01";
}
.dw-file-69:before {
	content: "\ed02";
}
.dw-file-70:before {
	content: "\ed03";
}
.dw-folder-76:before {
	content: "\ed04";
}
.dw-folder-77:before {
	content: "\ed05";
}
.dw-folder-78:before {
	content: "\ed06";
}
.dw-file-71:before {
	content: "\ed07";
}
.dw-file-72:before {
	content: "\ed08";
}
.dw-file-73:before {
	content: "\ed09";
}
.dw-folder-79:before {
	content: "\ed0a";
}
.dw-folder-80:before {
	content: "\ed0b";
}
.dw-folder-81:before {
	content: "\ed0c";
}
.dw-file-74:before {
	content: "\ed0d";
}
.dw-file-75:before {
	content: "\ed0e";
}
.dw-file-76:before {
	content: "\ed0f";
}
.dw-folder-82:before {
	content: "\ed10";
}
.dw-folder-83:before {
	content: "\ed11";
}
.dw-file-77:before {
	content: "\ed12";
}
.dw-file-78:before {
	content: "\ed13";
}
.dw-file-79:before {
	content: "\ed14";
}
.dw-folder-84:before {
	content: "\ed15";
}
.dw-folder-85:before {
	content: "\ed16";
}
.dw-folder-86:before {
	content: "\ed17";
}
.dw-file-80:before {
	content: "\ed18";
}
.dw-file-81:before {
	content: "\ed19";
}
.dw-file-82:before {
	content: "\ed1a";
}
.dw-folder-87:before {
	content: "\ed1b";
}
.dw-folder-88:before {
	content: "\ed1c";
}
.dw-folder-89:before {
	content: "\ed1d";
}
.dw-file-83:before {
	content: "\ed1e";
}
.dw-file-84:before {
	content: "\ed1f";
}
.dw-file-85:before {
	content: "\ed20";
}
.dw-folder-90:before {
	content: "\ed21";
}
.dw-folder-91:before {
	content: "\ed22";
}
.dw-folder-92:before {
	content: "\ed23";
}
.dw-file-86:before {
	content: "\ed24";
}
.dw-file-87:before {
	content: "\ed25";
}
.dw-file-88:before {
	content: "\ed26";
}
.dw-folder-93:before {
	content: "\ed27";
}
.dw-folder-94:before {
	content: "\ed28";
}
.dw-folder-95:before {
	content: "\ed29";
}
.dw-file-89:before {
	content: "\ed2a";
}
.dw-file-90:before {
	content: "\ed2b";
}
.dw-file-91:before {
	content: "\ed2c";
}
.dw-folder-96:before {
	content: "\ed2d";
}
.dw-folder-97:before {
	content: "\ed2e";
}
.dw-folder-98:before {
	content: "\ed2f";
}
.dw-file-92:before {
	content: "\ed30";
}
.dw-file-93:before {
	content: "\ed31";
}
.dw-file-94:before {
	content: "\ed32";
}
.dw-folder-99:before {
	content: "\ed33";
}
.dw-folder-100:before {
	content: "\ed34";
}
.dw-folder-101:before {
	content: "\ed35";
}
.dw-file-95:before {
	content: "\ed36";
}
.dw-file-96:before {
	content: "\ed37";
}
.dw-file-97:before {
	content: "\ed38";
}
.dw-folder-102:before {
	content: "\ed39";
}
.dw-folder-103:before {
	content: "\ed3a";
}
.dw-folder-104:before {
	content: "\ed3b";
}
.dw-file-98:before {
	content: "\ed3c";
}
.dw-file-99:before {
	content: "\ed3d";
}
.dw-file-100:before {
	content: "\ed3e";
}
.dw-folder-105:before {
	content: "\ed3f";
}
.dw-folder-106:before {
	content: "\ed40";
}
.dw-folder-107:before {
	content: "\ed41";
}
.dw-file-101:before {
	content: "\ed42";
}
.dw-file-102:before {
	content: "\ed43";
}
.dw-file-103:before {
	content: "\ed44";
}
.dw-folder-108:before {
	content: "\ed45";
}
.dw-folder-109:before {
	content: "\ed46";
}
.dw-folder-110:before {
	content: "\ed47";
}
.dw-file-104:before {
	content: "\ed48";
}
.dw-remove:before {
	content: "\ed49";
}
.dw-remove-1:before {
	content: "\ed4a";
}
.dw-folder-111:before {
	content: "\ed4b";
}
.dw-folder-112:before {
	content: "\ed4c";
}
.dw-folder-113:before {
	content: "\ed4d";
}
.dw-file-105:before {
	content: "\ed4e";
}
.dw-file-106:before {
	content: "\ed4f";
}
.dw-file-107:before {
	content: "\ed50";
}
.dw-folder-114:before {
	content: "\ed51";
}
.dw-folder-115:before {
	content: "\ed52";
}
.dw-folder-116:before {
	content: "\ed53";
}
.dw-file-108:before {
	content: "\ed54";
}
.dw-file-109:before {
	content: "\ed55";
}
.dw-file-110:before {
	content: "\ed56";
}
.dw-folder-117:before {
	content: "\ed57";
}
.dw-folder-118:before {
	content: "\ed58";
}
.dw-folder-119:before {
	content: "\ed59";
}
.dw-file-1111:before {
	content: "\ed5a";
}
.dw-file-112:before {
	content: "\ed5b";
}
.dw-file-113:before {
	content: "\ed5c";
}
.dw-folder-120:before {
	content: "\ed5d";
}
.dw-folder-121:before {
	content: "\ed5e";
}
.dw-folder-122:before {
	content: "\ed5f";
}
.dw-file-114:before {
	content: "\ed60";
}
.dw-file-115:before {
	content: "\ed61";
}
.dw-file-116:before {
	content: "\ed62";
}
.dw-folder-123:before {
	content: "\ed63";
}
.dw-folder-124:before {
	content: "\ed64";
}
.dw-folder-125:before {
	content: "\ed65";
}
.dw-file-117:before {
	content: "\ed66";
}
.dw-file-118:before {
	content: "\ed67";
}
.dw-file-119:before {
	content: "\ed68";
}
.dw-folder-126:before {
	content: "\ed69";
}
.dw-folder-127:before {
	content: "\ed6a";
}
.dw-folder-128:before {
	content: "\ed6b";
}
.dw-file-120:before {
	content: "\ed6c";
}
.dw-file-1211:before {
	content: "\ed6d";
}
.dw-file-122:before {
	content: "\ed6e";
}
.dw-folder-129:before {
	content: "\ed6f";
}
.dw-folder-130:before {
	content: "\ed70";
}
.dw-folder-131:before {
	content: "\ed71";
}
.dw-file-123:before {
	content: "\ed72";
}
.dw-file-124:before {
	content: "\ed73";
}
.dw-file-125:before {
	content: "\ed74";
}
.dw-folder-132:before {
	content: "\ed75";
}
.dw-folder-133:before {
	content: "\ed76";
}
.dw-folder-134:before {
	content: "\ed77";
}
.dw-file-126:before {
	content: "\ed78";
}
.dw-file-127:before {
	content: "\ed79";
}
.dw-file-128:before {
	content: "\ed7a";
}
.dw-folder-135:before {
	content: "\ed7b";
}
.dw-folder-136:before {
	content: "\ed7c";
}
.dw-folder-137:before {
	content: "\ed7d";
}
.dw-file-129:before {
	content: "\ed7e";
}
.dw-file-130:before {
	content: "\ed7f";
}
.dw-file-131:before {
	content: "\ed80";
}
.dw-folder-138:before {
	content: "\ed81";
}
.dw-folder-139:before {
	content: "\ed82";
}
.dw-folder-140:before {
	content: "\ed83";
}
.dw-file-132:before {
	content: "\ed84";
}
.dw-file-133:before {
	content: "\ed85";
}
.dw-file-134:before {
	content: "\ed86";
}
.dw-folder-141:before {
	content: "\ed87";
}
.dw-folder-142:before {
	content: "\ed88";
}
.dw-folder-143:before {
	content: "\ed89";
}
.dw-file-135:before {
	content: "\ed8a";
}
.dw-file-136:before {
	content: "\ed8b";
}
.dw-file-137:before {
	content: "\ed8c";
}
.dw-folder-144:before {
	content: "\ed8d";
}
.dw-folder-145:before {
	content: "\ed8e";
}
.dw-folder-146:before {
	content: "\ed8f";
}
.dw-file-138:before {
	content: "\ed90";
}
.dw-file-139:before {
	content: "\ed91";
}
.dw-file-140:before {
	content: "\ed92";
}
.dw-folder-147:before {
	content: "\ed93";
}
.dw-folder-148:before {
	content: "\ed94";
}
.dw-folder-149:before {
	content: "\ed95";
}
.dw-file-141:before {
	content: "\ed96";
}
.dw-file-142:before {
	content: "\ed97";
}
.dw-file-143:before {
	content: "\ed98";
}
.dw-folder-150:before {
	content: "\ed99";
}
.dw-folder-151:before {
	content: "\ed9a";
}
.dw-folder-152:before {
	content: "\ed9b";
}
.dw-file-144:before {
	content: "\ed9c";
}
.dw-video-file1:before {
	content: "\ed9d";
}
.dw-video-file-1:before {
	content: "\ed9e";
}
.dw-folder-153:before {
	content: "\ed9f";
}
.dw-folder-154:before {
	content: "\eda0";
}
.dw-folder-155:before {
	content: "\eda1";
}
.dw-video-file-2:before {
	content: "\eda2";
}
.dw-file-145:before {
	content: "\eda3";
}
.dw-file-146:before {
	content: "\eda4";
}
.dw-folder-156:before {
	content: "\eda5";
}
.dw-folder-157:before {
	content: "\eda6";
}
.dw-folder-158:before {
	content: "\eda7";
}
.dw-file-147:before {
	content: "\eda8";
}
.dw-file-148:before {
	content: "\eda9";
}
.dw-file-149:before {
	content: "\edaa";
}
.dw-folder-159:before {
	content: "\edab";
}
.dw-folder-160:before {
	content: "\edac";
}
.dw-folder-161:before {
	content: "\edad";
}
.dw-file-150:before {
	content: "\edae";
}
.dw-file-151:before {
	content: "\edaf";
}
.dw-file-152:before {
	content: "\edb0";
}
.dw-folder-162:before {
	content: "\edb1";
}
.dw-folder-163:before {
	content: "\edb2";
}
.dw-folder-164:before {
	content: "\edb3";
}
.dw-file-153:before {
	content: "\edb4";
}
.dw-wifi2:before {
	content: "\edb5";
}
.dw-webcam:before {
	content: "\edb6";
}
.dw-wallet1:before {
	content: "\edb7";
}
.dw-view:before {
	content: "\edb8";
}
.dw-video-camera2:before {
	content: "\edb9";
}
.dw-user-12:before {
	content: "\edba";
}
.dw-link-3:before {
	content: "\edbb";
}
.dw-upload2:before {
	content: "\edbc";
}
.dw-unlock:before {
	content: "\edbd";
}
.dw-undo2:before {
	content: "\edbe";
}
.dw-tick:before {
	content: "\edbf";
}
.dw-tag1:before {
	content: "\edc0";
}
.dw-suitcase2:before {
	content: "\edc1";
}
.dw-box-1:before {
	content: "\edc2";
}
.dw-stop:before {
	content: "\edc3";
}
.dw-sound:before {
	content: "\edc4";
}
.dw-slideshow:before {
	content: "\edc5";
}
.dw-shuffle2:before {
	content: "\edc6";
}
.dw-share-11:before {
	content: "\edc7";
}
.dw-share2:before {
	content: "\edc8";
}
.dw-settings2:before {
	content: "\edc9";
}
.dw-cursor-12:before {
	content: "\edca";
}
.dw-shield1:before {
	content: "\edcb";
}
.dw-loupe:before {
	content: "\edcc";
}
.dw-file-210:before {
	content: "\edcd";
}
.dw-balance:before {
	content: "\edce";
}
.dw-diskette2:before {
	content: "\edcf";
}
.dw-hourglass1:before {
	content: "\edd0";
}
.dw-ruler1:before {
	content: "\edd1";
}
.dw-next-2:before {
	content: "\edd2";
}
.dw-pie-chart1:before {
	content: "\edd3";
}
.dw-repeat-11:before {
	content: "\edd4";
}
.dw-repeat1:before {
	content: "\edd5";
}
.dw-refresh2:before {
	content: "\edd6";
}
.dw-books:before {
	content: "\edd7";
}
.dw-random1:before {
	content: "\edd8";
}
.dw-user2:before {
	content: "\edd9";
}
.dw-light-bulb:before {
	content: "\edda";
}
.dw-flash-1:before {
	content: "\eddb";
}
.dw-export:before {
	content: "\eddc";
}
.dw-pulse:before {
	content: "\eddd";
}
.dw-next-1:before {
	content: "\edde";
}
.dw-piggy-bank:before {
	content: "\eddf";
}
.dw-dropper:before {
	content: "\ede0";
}
.dw-smartphone2:before {
	content: "\ede1";
}
.dw-message-1:before {
	content: "\ede2";
}
.dw-paint-bucket1:before {
	content: "\ede3";
}
.dw-file-154:before {
	content: "\ede4";
}
.dw-bell1:before {
	content: "\ede5";
}
.dw-clipboard1:before {
	content: "\ede6";
}
.dw-newspaper-1:before {
	content: "\ede7";
}
.dw-newspaper:before {
	content: "\ede8";
}
.dw-antenna1:before {
	content: "\ede9";
}
.dw-bar-chart1:before {
	content: "\edea";
}
.dw-mute-1:before {
	content: "\edeb";
}
.dw-music-1:before {
	content: "\edec";
}
.dw-sound-waves:before {
	content: "\eded";
}
.dw-music:before {
	content: "\edee";
}
.dw-film:before {
	content: "\edef";
}
.dw-move-1:before {
	content: "\edf0";
}
.dw-move2:before {
	content: "\edf1";
}
.dw-mouse:before {
	content: "\edf2";
}
.dw-more2:before {
	content: "\edf3";
}
.dw-mute:before {
	content: "\edf4";
}
.dw-microphone-11:before {
	content: "\edf5";
}
.dw-microphone1:before {
	content: "\edf6";
}
.dw-message:before {
	content: "\edf7";
}
.dw-map-12:before {
	content: "\edf8";
}
.dw-placeholder:before {
	content: "\edf9";
}
.dw-low-battery:before {
	content: "\edfa";
}
.dw-map2:before {
	content: "\edfb";
}
.dw-link-2:before {
	content: "\edfc";
}
.dw-like1:before {
	content: "\edfd";
}
.dw-layers1:before {
	content: "\edfe";
}
.dw-key2:before {
	content: "\edff";
}
.dw-image-12:before {
	content: "\ee00";
}
.dw-image2:before {
	content: "\ee01";
}
.dw-link-1:before {
	content: "\ee02";
}
.dw-home:before {
	content: "\ee03";
}
.dw-headphones-1:before {
	content: "\ee04";
}
.dw-headphones1:before {
	content: "\ee05";
}
.dw-focus1:before {
	content: "\ee06";
}
.dw-fast-forward-1:before {
	content: "\ee07";
}
.dw-folder3:before {
	content: "\ee08";
}
.dw-flash1:before {
	content: "\ee09";
}
.dw-flag2:before {
	content: "\ee0a";
}
.dw-filter2:before {
	content: "\ee0b";
}
.dw-fast-forward:before {
	content: "\ee0c";
}
.dw-exit:before {
	content: "\ee0d";
}
.dw-expand2:before {
	content: "\ee0e";
}
.dw-email2:before {
	content: "\ee0f";
}
.dw-edit3:before {
	content: "\ee10";
}
.dw-dvd1:before {
	content: "\ee11";
}
.dw-download2:before {
	content: "\ee12";
}
.dw-down-arrow3:before {
	content: "\ee13";
}
.dw-file4:before {
	content: "\ee14";
}
.dw-delete-3:before {
	content: "\ee15";
}
.dw-delete-2:before {
	content: "\ee16";
}
.dw-delete-1:before {
	content: "\ee17";
}
.dw-browser-1:before {
	content: "\ee18";
}
.dw-cursor2:before {
	content: "\ee19";
}
.dw-crop1:before {
	content: "\ee1a";
}
.dw-chat-21:before {
	content: "\ee1b";
}
.dw-cloud1:before {
	content: "\ee1c";
}
.dw-wall-clock2:before {
	content: "\ee1d";
}
.dw-checked:before {
	content: "\ee1e";
}
.dw-chat-12:before {
	content: "\ee1f";
}
.dw-chat4:before {
	content: "\ee20";
}
.dw-link4:before {
	content: "\ee21";
}
.dw-cctv1:before {
	content: "\ee22";
}
.dw-shopping-cart2:before {
	content: "\ee23";
}
.dw-photo-camera-1:before {
	content: "\ee24";
}
.dw-photo-camera2:before {
	content: "\ee25";
}
.dw-calendar2:before {
	content: "\ee26";
}
.dw-bug2:before {
	content: "\ee27";
}
.dw-browser1:before {
	content: "\ee28";
}
.dw-broken:before {
	content: "\ee29";
}
.dw-brightness1:before {
	content: "\ee2a";
}
.dw-box:before {
	content: "\ee2b";
}
.dw-bookmark2:before {
	content: "\ee2c";
}
.dw-book2:before {
	content: "\ee2d";
}
.dw-board:before {
	content: "\ee2e";
}
.dw-bluetooth:before {
	content: "\ee2f";
}
.dw-alarm:before {
	content: "\ee30";
}
.dw-battery-11:before {
	content: "\ee31";
}
.dw-battery1:before {
	content: "\ee32";
}
.dw-ban:before {
	content: "\ee33";
}
.dw-shopping-bag1:before {
	content: "\ee34";
}
.dw-delete:before {
	content: "\ee35";
}
.dw-next:before {
	content: "\ee36";
}
.dw-megaphone:before {
	content: "\ee37";
}
.dw-add:before {
	content: "\ee38";
}
